.citiesPlan {
    width: 95%;
    margin: auto;
    max-width: 900px;
    color: #344854;
    .citiesPlanBanner {
        background-image: url(../../../../Images/citiesPlanCard.jpg);
        background-position: 40% 50%;
        background-size: cover;
        height: 19.5em;
        width: 100%;
        z-index: -1;
        top: 0%;
        left: 0%;
        position: absolute;
    }
    h3 {

    }
    .mainText {
        display: grid;
        grid-template-columns: 34em auto;
        @media screen and (max-width: 1100px) {
            display: grid;
            grid-template-columns: none;
            grid-template-rows: auto auto;
        }
        h4 {
            margin-top: 0;
        }
        .image {
            background-repeat: no-repeat;
            background-size: cover;
            margin: 1em 1em 1em 0em;
            background-position-y: 70%;
            background-position-x: 40%;
        }
        .tesla {
            background-image: url(../../../../Images/teslaPower.png);
            height: 20em;
        }
    }
    .figureText {
        font-style: italic;
    }
    
    .image {
        background-repeat: no-repeat;
        background-size: contain;
        margin: 1em 1em 1em 0em;
    }
    .greyBackground {
        background-color: white;
        padding: 0.2em 1em;
    }
}