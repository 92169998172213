.mainPageContainer {
    width: 95%;
    max-width: 900px;
    margin: auto;
    color: #344854;
    .aboutBanner {
        background-image: url(../Images/abbey.jpg);
        background-position: center;
        background-size: cover;
        height: 19.5em;
        width: 100%;
        z-index: -1;
        top: 0%;
        left: 0%;
        position: absolute;
    }
    h3 {
        padding-top: 5em;
    }
    .mainPageContent {
        @media screen and (min-width: 600px) {
            display: grid;
            grid-template-columns: auto auto;
            align-content: center;
            margin: auto;
        }
        .mainPagePicture {
            background-image: url(../Images/headingImage.jpg);
            width: 15em;
            background-repeat: no-repeat;
            background-size: 100%;
            margin-top: 1.4em;
            margin-right: 0.5em;
            @media screen and (max-width: 600px) {
                width: 100%;
                height: 20em;
            }
        }
        .mainPageText {
            padding: 0.2em;
        }
    }
    h4 {
        margin-top: 3em;
    }
}