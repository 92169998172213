.cultureAndEcology {
    width: 95%;
    margin: auto;
    max-width: 900px;
    color: #344854;
    .cultureAndEcologyBanner {
        background-image: url(../../../../Images/cultureAndEcologyBanner.jpg);
        background-position: 40% 50%;
        background-size: cover;
        height: 19.5em;
        width: 100%;
        z-index: -1;
        top: 0%;
        left: 0%;
        position: absolute;
    }
    h3 {

    }
    .mainText {
        display: grid;
        grid-template-columns: 32em auto;
        @media screen and (max-width: 1100px) {
            display: grid;
            grid-template-columns: none;
            grid-template-rows: auto auto;
        }
        h4 {
            margin-top: 0;
        }
        .image {
            background-repeat: no-repeat;
            background-size: cover;
            margin: 1em 1em 1em 0em;
            background-position-y: 70%;
            background-position-x: 40%;
        }
        .cultureAndEcologyImage {
            background-image: url(../../../../Images/cultureAndEcologyImage.jpg);
        }
    }
    .figureText {
        font-style: italic;
    }
    .image {
        background-repeat: no-repeat;
        background-size: contain;
        margin: 1em 1em 1em 0em;
        height: 20em;
        margin: auto;
    }
    .pygmyForestCamp {
        background-image: url(../../../../Images/pygmyForestCamp.jpg);
    }
    .ceremony{
        background-image: url(../../../../Images/ceremony.png);
    }
    .farmstead {
        background-image: url(../../../../Images/farmstead.jpg);
    }
    .herder {
        background-image: url(../../../../Images/herder.jpg);
    }
    .cattle {
        background-image: url(../../../../Images/cattle.jpg);
    }
    .riceHarvest {
        background-image: url(../../../../Images/riceHarvest.jpg);
    }
    .town {
        background-image: url(../../../../Images/town.png);
    }
    .soybeans {
        background-image: url(../../../../Images/soybeans.png);
    }
}