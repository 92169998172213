.forest {
    width: 95%;
    margin: auto;
    max-width: 900px;
    color: #344854;
    .forestBanner {
        background-image: url(../../../../Images/forestBanner.jpg);
        background-position: 20% 90%;
        background-size: cover;
        height: 19.5em;
        width: 100%;
        z-index: -1;
        top: 0%;
        left: 0%;
        position: absolute;
    }
    h3 {
    }
    .mainText {
        display: grid;
        grid-template-columns: 34em auto;
        h4 {
            margin: 0;
            margin-bottom: 0.2em;
        }
        @media screen and (max-width: 1100px) {
            display: grid;
            grid-template-columns: none;
            grid-template-rows: auto auto;
        }
        .image {
            background-repeat: no-repeat;
            background-size: cover;
            margin: 1em 1em 1em 0em;
            background-position-y: 25%;
            background-position-x: 40%;
            @media screen and (max-width: 1100px) {
                margin: 1em 0;
            }
        }
        .forest {
            background-image: url(../../../../Images/forestLandscape.jpg);
            height: 20.2em;
        }
    }
}