.header {
    position: relative;
    padding-top: 0.5em;
    font-family: 'Crimson Text', serif;
    font-size: 1.5em;
    width: 95%;
    max-width: 1200px;
    margin: auto;
    a {
        text-decoration: none !important;
        .headerContainer {
            h1 {
                color: white;
                text-align: center;
                padding: 0.5em 0 1em 0;
                text-shadow: 1px 1px black;
                &:visited {
                    color: black;
                }
            }
        }
    }
    .socialMediaButtons {
        text-align: right;
        margin-top: 1em;
        bottom:0;
        a {
            i {
                font-size: 2em;
                margin-bottom: 1em;
                color: #000000;
                bottom: 0;
                width: 1em;
                bottom:0;
                &:hover {
                    color: #343A40;
                    cursor: pointer;
                }
                &:active {
                    color: #343A40;
                    cursor: pointer;
                }
            }
        }
    }
}