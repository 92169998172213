.books {
    width: 95%;
    margin: auto;
    max-width: 1200px;
    color: #344854;
    .booksBanner {
        background-image: url(../../Images/books.jpg);
        background-position: 20% 50%;
        background-size: cover;
        height: 19.5em;
        width: 100%;
        z-index: -1;
        top: 0%;
        left: 0%;
        position: absolute;
    }
    .bookTitle {
        padding-top: 4em;
        padding-bottom: 1em;
        color: #344854;
    }
    .booksContainer {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
        column-gap: 1.5em;
        row-gap: 1.5em;
        justify-self: center;
        margin: 0.2em auto;
        .bookCard {
            color: #344854;
            justify-self: stretch;
            padding-bottom: 0.5em;
            background-color: white;
            box-shadow: 0 20px 20px rgba(0,0,0,.08);
            padding-bottom: 1em;
            .bookCardText {
                padding: 0.1em 1em;
                .blockContainer {
                    display: grid;
                    grid-template-columns: auto auto;
                    font-size: 1.2em;
                    font-weight: 600;
                    .block1 {
                        p {
                            margin-bottom: 0.5em;
                        }
                    }
                    .block2 {
                        p {
                            float: right;
                            margin-bottom: 0;
                        }
                    }
                }
                .linksContainer {
                    display: grid;
                    grid-template-rows: auto auto;
                    row-gap: 0.5em;
                    a{
                        color: #344854;
                        text-decoration: none;
                        // border: 0.1em solid #344854;
                        padding: 0.5em;
                        font-weight: bold;
                        &:hover {
                            background-color:  #344854;
                            color: white;
                        }
                    }
                }
            }
            .bookImage {
                height: 25em;
                margin: 2em 1em;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
            .book1 {
                background-image: url(../../Images/creatingRegenerativeCities);
            }
            .book2 {
                background-image: url(../../Images/aRenewableWorld.jpg);
            }
            .book13 {
                background-image: url(../../Images/citiesPeoplePlanet.jpg);
            }
            .book3 {
                background-image: url(../../Images/survivingTheCentury.jpg);
            }
            .book4 {
                background-image: url(../../Images/creatingWorldFutureCouncil.jpg);
            }
            .book5 {
                background-image: url(../../Images/nhk.jpg);
            }
            .book6 {
                background-image: url(../../Images/creatingSustainableCities.jpg);
            }
            .book7 {
                background-image: url(../../Images/makingCitiesWork.jpeg);
            }
            .book8 {
                background-image: url(../../Images/gaiaAtlasOfCities.jpg);
            }
            .book9 {
                background-image: url(../../Images/earthrise.jpg);
            }
            .book10 {
                background-image: url(../../Images/blueprintForAGreenPlanet.jpg);
            }
            .book11 {
                background-image: url(../../Images/farFromParadise.jpg);
            }
            .book12 {
                background-image: url(../../Images/landForThePeople.jpg);
            }
        }
    }
}