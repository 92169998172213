.greenPlanet {
    width: 95%;
    max-width: 1200px;
    margin: auto;
    .greenPlanetBanner {
        background-image: url(../../../Images/greenPlanet.jpg);
        background-position: 20% 50%;
        background-size: cover;
        height: 19.5em;
        width: 100%;
        z-index: -1;
        top: 0%;
        left: 0%;
        position: absolute;
    }
    h3 {
        color: #344854;
    }
    .articlesContainer {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
        column-gap: 1.5em;
        row-gap: 1.5em;
        justify-self: center;
        margin: 0.2em auto;
        a {
            color: #344854;
            text-decoration: none;
            .articleCard {
                justify-self: stretch;
                padding-bottom: 0.5em;
                background-color: white;
                box-shadow: 0 20px 20px rgba(0,0,0,.08);
                @media only screen and (min-width: 700px){
                    height: 30em;
                }
                &:hover {
                    box-shadow: 0 40px 40px rgba(0,0,0,.16);
                    transition: box-shadow 200ms cubic-bezier(.02, .01, .47, 1), transform 200ms cubic-bezier(.02, .01, .47, 1);
                    transform: translate(0,-20px);
                    transition-delay: 0s !important;
                }
                .articleCardText {
                    padding: 0.1em 0.8em;
                    .blockContainer {
                        display: grid;
                        grid-template-columns: auto auto;
                        font-size: 1.2em;
                        font-weight: 600;
                        .block1 {
                            p {
                                margin-bottom: 0.5em;
                            }
                        }
                        .block2 {
                            p {
                                float: right;
                                margin-bottom: 0;
                            }
                        }
                    }
                    .cardDescription {
                        font-size: 0.9em; 
                    }
                }
                .articleImage {
                    height: 16em;
                    margin: auto;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                }
                .amazonPicture {
                    background-image: url(../../../Images/theViewFromTheAmazon.png);
                }
                .natureRevengePicture {
                    background-image: url(../../../Images/nature-taking-revenge.png);
                }
                .callToAction {
                    background-image: url(../../../Images/callToAction.jpg);
                }
                .theForest {
                    background-image: url(../../../Images/forestPortrait.jpg);
                }
                .landtoseaCard {
                    background-image: url(../../../Images/landtoseaCard.jpg);
                }
                .cultureAndEcologyCard {
                    background-image: url(../../../Images/cultureAndEcologyCard.jpg);
                }
            }
        }
    }
}