.landingPage {
    background-image: url(../Images/birdsEyeView.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    .mainText {
        color: whitesmoke;
        display: grid;
        grid-template-columns: auto 1fr;
        position: relative;
        top: 30%;
        left: 30%;
        margin: auto;
        @media screen and (max-width: 1000px) {
            left: 25%;
        }
        @media screen and (max-width: 800px) {
            grid-template-columns: none;
            grid-template-rows: auto auto;
            top: 10%;
            left: 10%;
        }
        h1 {
            text-decoration: none;
            font-size: 3.5em;
            font-family: 'Crimson Text', serif;
            text-shadow: 1px 1px black;
            @media screen and (max-width: 800px) {
                margin: 0;
            }
            @media screen and (max-width: 600px) {
                font-size: 2.5em;
                margin: 0;
                margin: auto;
                width: 600px;
            }
        }
        ul {
            padding-top: 3.8em;
            list-style-type: none;
            font-size: 0.8em;
            width: 30em;
            text-shadow: 1px 1px black;
            @media screen and (max-width: 800px) {
                padding: 0;
                padding-left: 0.2em;
                font-size: 0.8em;
            }
            li {
                font-size: 2em;
                padding-top: 0.2em;
                a {
                    text-decoration: none;
                    color: whitesmoke;
                }
            }
            .dropLink {
                &:hover {
                    cursor: pointer;
                }
            }
            .links {
                visibility: hidden;
                transition: all 0.5s ease-in-out;
                opacity: 0;
                height: 0;
                overflow: hidden;
                padding: 0;
                padding-left: 1em;
                font-size: 0.7em;
                @media screen and (max-width: 600px) {
                    font-size: 0.8em;
                }
            }
            &.show {
                visibility: visible;
                opacity: 100;
                height: auto;
            }
            
        }
    }
}