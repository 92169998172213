.urbanSystems {
    width: 95%;
    margin: auto;
    max-width: 900px;
    color: #344854;
    .urbanSystemsBanner {
        background-image: url(../../../../Images/urbanSystemsBanner.jpg);
        background-position: 20% 50%;
        background-size: cover;
        height: 19.5em;
        width: 100%;
        z-index: -1;
        top: 0%;
        left: 0%;
        position: absolute;
    }
    h3 {

    }
    .mainText {
        display: grid;
        grid-template-columns: 34em auto;
        @media screen and (max-width: 1100px) {
            display: grid;
            grid-template-columns: none;
            grid-template-rows: auto auto;
        }
        .image {
            background-repeat: no-repeat;
            background-size: cover;
            margin: 1em 1em 1em 0em;
            background-position-y: 25%;
            background-position-x: 40%;
        }
        .urbanSystemsImage {
            background-image: url(../../../../Images/urbanSystemsImage.jpg);
        }
    }
    .gridContainer {
        display: grid;
        grid-template-columns: 28em auto;
        @media screen and (max-width: 1100px) {
            display: grid;
            grid-template-columns: none;
            grid-template-rows: auto auto;
        }
        div {

        }
        .figure1 {
            background-repeat: no-repeat;
            background-size: contain;
            margin: 3.5em 1em 1em 3em;
            background-image: url(../../../../Images/metabolismDiagram.jpg);
            height: 33em;
            @media only screen and (max-width: 700px){
                margin: 0;
            }
        }
    }
    .image {
        background-repeat: no-repeat;
        background-size: contain;
        margin: 1em 1em 1em 0em;
    }
    .figureText {
        font-style: italic;
    }
    .figure2 {
        background-image: url(../../../../Images/agropolisDiagram.jpg);
        height: 30em;
    }
    .figure3 {
        background-image: url(../../../../Images/petropolisDiagram2.jpg);
        height: 30em;
    }
    .figure4 {
        background-image: url(../../../../Images/ecopolisDiagram.jpg);
        height: 30em;
    }
}