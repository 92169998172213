.navbar {
    width: 95%;
    max-width: 1200px;
    margin: auto;
    font-family: 'Roboto', sans-serif;
    color: #344854;
    .icon {
        display: none;
        padding: 16px;
        font-size: 16px;
        color: black;
        margin-bottom: 0.1em;
        @media screen and (max-width: 600px) {
            display: block;
            background-color: rgba(230, 230, 230, 0.75);
        }
    }
    .buttonsContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        margin-bottom: 0.2em;
        // column-gap: 0.2em;
        row-gap: 0.2em;
        @media screen and (max-width: 600px) {
            display: none;
            grid-template-columns: none;
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
            row-gap: 0.1em;
            position: relative;
            text-align: left;
            &.active {
                display: grid;
                grid-template-columns: none;
                grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
            }
        }
        .dropdown {
            position: relative;
            display: inline-block;
            &:hover {
                box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                .dropdownContent {
                    display: block;
                    transition: all 0.5s ease-in-out;
                }
                .dropbtn {
                    background-color: black;
                    color: white;
                }
            }
            .dropbtn {
                background-color: rgba(255, 255, 255, .8);
                color: black;
                padding: 16px;
                font-size: 16px;
                border: none;
                width: 100%;
                text-align: center;
                font-family: 'Roboto', sans-serif;
                font-size: 1.2em;
            }
            .nonListLink {
                &:hover {
                    cursor: pointer;
                }
            }
            .dropdownContent {
                display: none;
                position: absolute;
                background-color: #fcfcfc;
                min-width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                z-index: 1;
                width: 100%;
                .dropDownTitle {
                    text-decoration: underline;
                    padding: 12px 10px;
                }
                a {
                    color: black;
                    padding: 12px 16px;
                    text-decoration: none;
                    display: block;
                    &:hover {
                        background-color: #ddd;
                    }
                }
            }
        }
    }
}
