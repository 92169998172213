.carbon {
    width: 95%;
    margin: auto;
    max-width: 900px;
    color: #344854;
    .carbonBanner {
        background-image: url(../../../Images/desertWind.jpg);
        background-position: 10% 70%;
        background-size: cover;
        height: 19.5em;
        width: 100%;
        z-index: -1;
        top: 0%;
        left: 0%;
        position: absolute;
    }
    h3 {
        padding-top: 5em;
        padding-bottom: 2em;
    }
    .chapterDescription {
        font-style: italic;
    }
    .carbonFig {
        background-image: url('../../../Images/carbonFig.png');
        background-size: cover;
        height: 19.5em;
        width: 100%;
    }
}